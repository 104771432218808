<template>
	<el-dialog
		:visible.sync="visible"
		:show-close="false"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		class="role-dialog"
		width="750px">
		<span slot="title" class="dialog-title">
			<span>{{title}}</span>
			<img src="@/assets/images/close.png" alt="" @click="visible = false">
		</span>
		<el-form :model="dataForm" :rules="dataRule" label-position="top" ref="dataForm">
			<el-row :gutter="20">
				<el-col :span="12">
					<el-form-item label="Advance编码" prop="advanceCode">
						<el-input v-model="dataForm.advanceCode" placeholder="请输入" maxlength="32" :disabled="editType === 'edit' || editType ==='check'"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="开票编号" prop="invoiceNumber">
						<el-input v-model="dataForm.invoiceNumber" placeholder="请输入" maxlength="32" :disabled="editType === 'edit' || editType === 'check'"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-form-item label="公司名称" prop="companyName">
						<el-input v-model="dataForm.companyName" placeholder="请输入" maxlength="32" :disabled="readonly"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="20">
				<el-col :span="12">
					<el-form-item label="省" prop="province">
						<el-input v-model="dataForm.province" placeholder="请输入" maxlength="32" :disabled="readonly"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="市" prop="city">
						<el-input v-model="dataForm.city" placeholder="请输入" maxlength="32" :disabled="readonly"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-form-item label="详细地址" prop="address">
						<el-input v-model="dataForm.address" :rows="4" type="textarea" placeholder="请输入" maxlength="128" :disabled="readonly"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="20">
				<el-col :span="12">
					<el-form-item label="联系人" prop="contact">
						<el-input v-model="dataForm.contact" placeholder="请输入" maxlength="32" :disabled="readonly"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="联系人电话" prop="telephone">
						<el-input v-model.number="dataForm.telephone" placeholder="请输入" maxlength="11" :disabled="readonly"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button v-if="!readonly" :loading="submitLoading" class="submit-btn" @click="submit">确定</el-button>
		</span>
	</el-dialog>
</template>
<script>
import { debounce } from '@/utils/debounce'

export default {
	data() {
		return {
			title: '新增送货地址',
			visible: false,
			readonly: false,
			editType: 'add',
			submitLoading: false,
			dataForm: {
				id: '',
				advanceCode: '', // Advance编码
				invoiceNumber: '', // 开票编号
				companyName: '', // 公司名称
				address: '', // 详细地址
				province: '', // 省
				city: '', // 市
				contact: '', // 联系人
				telephone: '', // 联系人电话
			},
			submitUrl: 'repairModelAdd',
			dataRule: {
				advanceCode: [
					{ required: true, message: 'Advance编码不能为空', trigger: "blur" }
				],
				invoiceNumber: [
					{ required: true, message: '开票编号不能为空', trigger: "blur" }
				],
				companyName: [
					{ required: true, message: '公司名称不能为空', trigger: "blur" }
				],
				address: [
					{ required: true, message: '详细地址不能为空', trigger: "blur" }
				],
				province: [
					{ required: true, message: '省不能为空', trigger: "blur" }
				],
				city: [
					{ required: true, message: '市不能为空', trigger: "blur" }
				],
				contact: [
					{ required: true, message: '联系人不能为空', trigger: "blur" }
				],
				telephone: [
					{ required: true, message: '联系人电话不能为空', trigger: "blur" }
				],
			},
		}
	},
	created() {
	},
	methods: {
		init(type, data) {
			this.visible = true;
			this.$nextTick(() => {
				this.$refs.dataForm.resetFields();
				this.editType = type;
				this.dataForm ={
					...data
				}
				if (type === 'add') {
					this.title = '新增送货地址';
					this.submitUrl = 'repairRepairAdvanceAdd';
					this.readonly = false;

					delete this.dataForm.id;
				} else if (type === 'edit') {
					this.title = '修改送货地址';
					this.submitUrl = 'repairRepairAdvanceUpdate';
					this.readonly = false;
				} else {
					this.title = '查看送货地址';
					this.submitUrl = '';
					this.readonly = true;
				}
			})
		},
		submit: debounce(function () {
			if (this.visible == false) {
				return
			}
			this.$refs['dataForm'].validate(valid => {
				if (valid) {
					let that = this;
					that.submitLoading = true
					that.$request.post(
						that.submitUrl,
						false,
						that.dataForm,
						function (r) {
							if (r.code == "0") {
								that.visible = false;
								that.$emit('refresh');
								that.$message.success('保存成功');
							} else {
								that.$message.error(r.msg);
							}
							that.submitLoading = false
						}
					);
				} else {
					return false;
				}
			});
		}, 1000),
	}
}
</script>
<style lang="scss" scoped>
.dialog-title {
	display: block;
	text-align: center;

	span {
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}

	img {
		width: 28px;
		float: right;
		cursor: pointer;
	}
}

.el-form-item {
	margin-bottom: 10px;

	.el-select {
		width: 100%;
	}
}

.submit-btn {
	width: 100%;
	background-color: #117FFC;
	color: #fff;
}

.role-dialog /deep/ .el-dialog {
	border-radius: 10px !important;
}

.role-dialog /deep/ .el-dialog__body {
	padding: 0 20px !important;
}

.role-dialog /deep/ .el-form-item__label {
	padding: 0 !important;
}

.role-dialog /deep/ .el-dialog__footer {
	padding: 20px 20px 30px !important;
}
</style>
